// ErrorSelect.tsx
import React, { useState } from "react";
import { Select } from "../ui/Select";
import { Label } from "@/shared/components/ui/label";
import StripeTest from "@/playwright/utils/Stripe.test.utils";
import { useCopyToClipboard } from "@/shared/hooks/useCopyToClipboard";

export default function CreditCardTestSelect() {
    const [copiedText, copy] = useCopyToClipboard();
    const [selectedCard, setSelectedCard] = useState<string | undefined>(undefined);

    const cards = Object.entries(StripeTest.CARDS).map(([key, value]) => ({
        label: key,
        value: value
    }));

    const handleCopy = async (id: string) => {
        await copy(id);
    };

    const handleCardSelect = (id: string) => {
        setSelectedCard(id);
        handleCopy(id);
    };

    return (
        <div className="w-auto">
            <Label id="force-billing-error-label" htmlFor="force-billing-error" className="mb-0 bg-background-transparent text-sm">
                Test Cards
            </Label>
            <div className="flex items-center gap-2">
                <Select key={selectedCard} placeholder="Select Card" value={selectedCard} options={cards} onValueChange={handleCardSelect} />
            </div>
        </div>
    );
}
