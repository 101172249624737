// ErrorSelect.tsx
import React, { useState } from "react";
import { Select } from "../ui/Select";
import { Label } from "@/shared/components/ui/label";
import { ErrorIdType, allSpotErrorIds } from "@/shared/types/SpotAPI";
import { Button } from "../ui";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

export const ErrorSelect = () => {
    const updateAppState = useAppLayerContext().updateAppState;
    const mockBillingError = useAppLayerContext().appState?.mockBillingError;

    const options = allSpotErrorIds.map(id => ({ value: id, label: id })) as { value: ErrorIdType; label: string }[];

    const [selectedErrorId, setSelectedErrorId] = useState<ErrorIdType | undefined>(mockBillingError);

    const handleForceBillingErrorChange = (errorId: string) => {
        setSelectedErrorId(errorId as ErrorIdType);
        updateAppState({ mockBillingError: errorId as ErrorIdType });
    };

    const clearBillingError = () => {
        setSelectedErrorId(undefined);
        updateAppState({ mockBillingError: undefined });
    };

    return (
        <div className="w-auto">
            <Label id="force-billing-error-label" htmlFor="force-billing-error" className="mb-0 bg-background-transparent text-sm">
                Force API Error
            </Label>
            <div className="flex items-center gap-2">
                <Select key={selectedErrorId} placeholder="Select Error ID" value={selectedErrorId} options={options} onValueChange={handleForceBillingErrorChange} />
                {!!selectedErrorId && (
                    <Button variant="destructive" disabled={!selectedErrorId} onClick={clearBillingError}>
                        Clear
                    </Button>
                )}
            </div>
        </div>
    );
};

ErrorSelect.displayName = "ErrorSelect";
